@import '../../scss/colors';

$labelLineHeight: 1.5;
$labelFontSize: 16px;
$checkboxDimensions: 16px;
$checkboxTop: calc((($labelLineHeight * $labelFontSize) - $checkboxDimensions) / 2);

.Checkbox {
  position: relative;
  padding-left: $checkboxDimensions + 8px;
  font-size: 16px;
  line-height: $labelLineHeight;
  color: $gray;
  display: block;

  &.error .icon {
    border-color: $error;
  }

  input {
    height: $checkboxDimensions;
    width: $checkboxDimensions;
    position: absolute;
    top: $checkboxTop;
    left: 0;
    opacity: 0;

    + .icon {
      content: '';
      height: $checkboxDimensions;
      width: $checkboxDimensions;
      position: absolute;
      top: $checkboxTop;
      border: 1px solid $gray;
      border-radius: 3px;
      box-sizing: border-box;
      left: 0;
    }

    &:checked + .icon {
      background: $primary;
      border-color: $primary;
    }

    &:checked + .icon:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 5px;
      border: 2px solid #fff;
      border-left: 0;
      border-top: 0;
      height: 10px;
      width: 4px;
      transform: rotate(45deg);
    }

    &:active + .icon,
    &:focus + .icon {
      border-color: $primary;
    }
  }
}

.CheckboxGroup {
  label {
    margin-bottom: 8px;
  }
}

.CheckboxGroupWrapper {
  width: 100%;
}

.CheckboxGroupRow.row {
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
}
