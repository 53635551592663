@import '../../scss/colors';

.LargeHeader {
  background: white;
  box-shadow: 0 1px 20px 0 $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
  padding: 40px 0;

  .Title {
    margin-bottom: 0;
  }

  .LargeHeaderInformationBar {
    overflow: auto;
    font-weight: bold;
    margin-bottom: 20px;

    & > .LargeHeaderInformation {
      float: left;
      margin-right: 15px;
    }

    & > .LargeHeaderInformation:last-child {
      margin-right: 0;
    }

    .LargeHeaderInformationIcon {
      fill: $primary;
      margin-right: 5px;
    }
  }
}
