@import '../../scss/colors';

.EventHeader {
  background: white;
  box-shadow: 0 1px 20px 0 $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
  padding: 40px 0;

  .EventName {
    margin-bottom: 0;
  }

  .EventInformationBar {
    overflow: auto;
    font-weight: bold;
    margin-bottom: 20px;

    & > .EventInformation {
      float: left;
      margin-right: 15px;

      a, a:hover, a:active, a:visited {
        text-decoration: none;
      }

      a {
        color: $dark-gray;
      }

      a:hover {
        color: $primary;
      }
    }

    & > .EventInformation:last-child {
      margin-right: 0;
    }

    .EventInformationIcon {
      fill: $primary;
      margin-right: 5px;
    }
  }

  .EventDescription {
    font-size: 18px;
    line-height: 1.44;
  }

  .EventTicketTypes {
    border-top: 1px solid $border;
    margin-top: 40px;
  }

  .EventTicketTypes a {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: 0.3px;
    color: $gray;
    text-transform: uppercase;
  }

  .EventTicketTypes a:hover {
    opacity: 0.8;
  }

  .EventCart {
    > div {
      position: absolute;
      width: 100%;
    }
  }
}

.EventHeaderSmallTop {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 100;
  padding: 20px 0;

  .EventInformationBar {
    margin: 0;
    text-align: right;

    .EventInformation {
      float: none;
      display: inline-block;
      margin-right: 10px;
      font-size: 13px;
      padding: 5px 0px;
    }
  }

  .EventName {
    font-size: 2rem;
  }
}

@media (max-width: 767.98px) {
  .EventHeaderSmallTop {
    display: none;
  }
}
