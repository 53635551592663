@import '../../scss/const';
@import '../../scss/colors';

.Input {
  .form-control {
    background-color: $gray-3;
    border: 0;
    padding: 20px 12px;

    &::-moz-placeholder {
      color: $gray;
    }

    &:-ms-input-placeholder {
      color: $gray;
    }

    &::-webkit-input-placeholder {
      color: $gray;
    }
  }
}

.InputError {
  font-size: 12px;
  color: $error;
}
