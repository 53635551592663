@import '../../scss/const';
@import '../../scss/colors';

.Breadcrumbs {
  padding: 30px 0;

  .Breadcrumb {
    pointer-events: none;
    cursor: default;
    color: $gray;
    font-weight: bold;

    &.Active, &.Completed {
      color: $dark-gray;
      pointer-events: initial;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }
  }

  .BreadcrumbDivider {
    margin: 0 15px;

    svg {
      fill: $primary;
    }
  }

}

@media (max-width: 767.98px) {
  .Breadcrumbs {
    padding: 15px 0;

    > * {
      display: none;
    }
  }
}
