@import '../../scss/const';
@import '../../scss/colors';

.Alert {
  display: flex;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-color: $primary-light;
  margin-bottom: 10px;
  padding: 20px;

  &.Alert_Block {
    box-shadow: 0 2px 20px 0 $shadow-dark;
  }

  &.Alert_Inline {
    margin: 10px 0;
  }
}

.AlertIcon {
  float: left;
  margin-right: 10px;
}

.Alert .AlertIcon svg path {
  fill: $primary;
}

.Alert strong {
  color: $primary;
}
