@import '../../../scss/const';
@import '../../../scss/colors';

.ThanksGif {
  margin-bottom: 15px;

  img {
    width: 100%;
  }
}
