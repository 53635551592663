@import '../../scss/const';
@import '../../scss/colors';

.EventTile {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  border: 0 !important;
  overflow: hidden;

  .EventTileHeader img {
    width: 100%;
  }

  .EventInformation {
    overflow: auto;
    margin-bottom: 10px;

    & > .EventInformation {
      float: left;
      margin-right: 15px;
    }

    & > .EventInformation:last-child {
      margin-right: 0;
    }

    .EventInformationIcon {
      fill: $primary;
      margin-right: 5px;
    }
  }

  .EventTicketButton {
    text-align: center;
  }
}
