@import '../../../scss/const';
@import '../../../scss/colors';

.AccountTickets .Main {
  padding-top: 30px;
}

.OrderItemHeader {
  border-bottom: 1px solid $gray-3;
  padding: 10px 0;
  font-weight: bold;
  color: $gray;
}

.OrderItemAmount {
  //text-align: right;
}

@media (max-width: 767.98px) {
  .OrderItemHeader {
    display: none;
  }

  .OrderItemAmount {
    text-align: left;
  }
}
