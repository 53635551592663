@import './colors';

$header-base: 1.4rem !default;
$h1-font-size: $header-base * 2.5 !default;
$h2-font-size: $header-base * 2 !default;
$h3-font-size: $header-base * 1.75 !default;
$h4-font-size: $header-base * 1.5 !default;
$h5-font-size: $header-base * 1.25 !default;
$h6-font-size: $header-base !default;

.btn {
  border: 0 !important;

  &.disabled, &:disabled {
    background-color: $disabled-gray;
    opacity: 1;
  }

  &.btn-primary, &.btn-success {
    font-family: $font-martin;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1 !important;
    padding: 8px 16px !important;

    &.disabled, &:disabled {
      background-color: $disabled-gray;
      opacity: 1;
    }
  }

  &.btn-success {
    color: $white;

    &.disabled, &:disabled {
      color: $white;
    }
  }

  &.btn-light {
    background: none;
    font-weight: bold;
    color: $gray;

    svg {
      fill: $gray;
    }

    &:hover {
      svg {
        fill: $dark-gray;
      }
    }
  }
}

.btn-lg, .btn-group-lg > .btn {
  line-height: 1 !important;
  font-size: 28px !important;
  padding: 10px 12px !important;
}