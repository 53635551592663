@import '../../../scss/const';
@import '../../../scss/colors';

.VolunteerContainer {
  padding-top: 30px;
}

.VolunteerForm {
  padding: 0;
}

.DetailsBlock {
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  padding: 20px;
}

.CartBlock {
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  overflow: hidden;
  padding: 0 !important;
}

.TeamHeading {
  border-top: 1px solid $border;
  padding-top: 20px;
}
