@import '../../scss/const';
@import '../../scss/colors';

.ContactMethods {
  margin-top: 20px;

  .ContactMethod {
    margin-bottom: 15px;

    .ContactMethodText {
      line-height: 18px;

      a {
        color: black;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .ContactMethodSubText {
      color: $gray;
      font-size: 14px;
    }

    svg {
      width: 24px;
      height: auto;


    }
  }
}
