@import '../../../scss/const';
@import '../../../scss/colors';

.AccountOrderButtons {
  text-align: right;
  margin-bottom: 10px;

  .AccountOrderButton {
    font-weight: bold;

    .AccountOrderButtonIcon {
      margin-left: 5px;
    }

    &:hover {
      text-decoration: none;
    }


    &:disabled {
      background: none;
    }

    .TicketLoader {
      width: 24px;
      margin-left: 5px;
      display: inline-block;
    }
  }
}

.AccountOrdersOrder .Main {
  padding-top: 30px;
}

.OrderEventName {
  margin-bottom: 0;
}

.OrderEventDetails {
  font-weight: bold;
  margin-bottom: 10px;

  .OrderEventDetailsIcon {
    margin-right: 5px;

    path {
      fill: $primary;
    }
  }
}
