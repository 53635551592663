@import '../../scss/const';
@import '../../scss/colors';


.CartRemoveMessage {
  border-radius: $radius;
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  padding: 20px;
  min-width: 400px;
  border: none !important;

  right: 0;
  left: initial !important;

  .popover-body {
    padding: 0;
  }

  .CartRemoveMessageText {
    margin-bottom: 10px;
  }

  .ConfirmButtons {
    text-align: center;
  }
}
