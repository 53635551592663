@import '../../scss/colors';

body {
  padding-top: 80px;
}

.Header.navbar {
  background: white;
  border-bottom: 1px solid $background;
  padding: 19px 20px;

  .Logo {
    padding: 0;

    & > * {
      width: 49px;
      height: 41px;
      margin: 0 15px;
    }
  }

  .Navigation a.nav-link {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: $dark-gray;
  }

  .dropdown-toggle::after {
    margin-left: 8px;
  }

  .MenuIcon {
    fill: #2a2822;
    margin-right: 5px;
    height: 18px;
    width: 18px;
    margin-top: -2px;
  }

  .active .MenuIcon {
    fill: $white;
  }

  .dropdown-menu {
    padding: 0;
    overflow: hidden;
    border: 0;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

    .dropdown-item {
      padding: 0.5rem 1.5rem;
    }
  }
}
