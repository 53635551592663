@import '../../scss/const';
@import '../../scss/colors';

.EventTickets {
  margin-top: 40px;

  .EventTicket {
    margin-bottom: 40px;

    .EventTicketName {
      font-size: 30px;
      margin-bottom: 15px;
    }
  }

  .EventTicketVariety {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
    box-shadow: 0 2px 20px 0 $shadow-dark;
    background-color: $white;
    margin-bottom: 10px;

    .EventTicketVarietyDescription {
      line-height: 1.5;
    }

    &.Opened .EventTicketVarietyHeader {
      padding-bottom: 0;
    }

    .EventTicketVarietyHeader {
      overflow: auto;
      cursor: pointer;
      padding: 20px;

      .DiscountBadge {
        background-color: $yellow;
        color: $red;
        margin-left: 10px;
        font-size: 18px;
        font-family: $font-martin;
        text-transform: uppercase;
        display: inline-block;
        padding: 5px 8px;
        margin-top: 1px;
        line-height: 1;
        float: left;
      }

      .EventTicketVarietyName {

        h3 {
          display: inline-block;
          font-size: 24px;
          float: left;
        }
      }

      .EventTicketVarietyHeaderPrice, .EventTicketVarietyDropDown {
        float: right;
        margin-left: 10px;
      }

      .EventTicketVarietyDropDown {
        padding: 0;

        &.Show {
          transform: rotate(180deg);
        }
      }

      .EventTicketVarietyShowHide {
        fill: $gray-2;
      }
    }

    .EventTicketVarietyBody {
      padding: 0 20px;
    }

    .EventTicketVarietyFooter {
      margin-top: 20px;
      padding-bottom: 20px;

      .EventTicketVarietyPrice .EventTicketVarietyPriceCurrent {
        font-family: $font-martin;
        text-transform: uppercase;
        font-size: 24px;
      }

      .EventTicketVarietyPrice .EventTicketVarietyPriceNext {
        font-family: $font-aeonik;
        font-size: 18px;
        color: $gray;
        text-decoration: line-through;
        margin-left: 10px;
      }

      .EventTicketVarietyPriceTill {
        color: $gray;
        font-size: 13px;
      }
    }
  }
}


.EventTicketVarietyAmount {
  text-align: right;

  > * {
    display: inline-block !important;
  }

  .TicketButton {
    height: 50px;

    &.TicketMinus {
      background-color: $gray;
    }

    &.TicketPlus {
      background-color: $green;
    }
  }

  .TicketAmount.form-control {
    background-color: $gray-3;
    margin: 0 10px;
    width: 70px;
    padding: 6px 12px;
    height: 50px;
    text-align: center;
    border: 0;
  }
}

.EventTicketAnchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}


@media (max-width: 767.98px) {
  .EventTickets .EventTicketVariety .EventTicketVarietyHeader .EventTicketVarietyName {
    float: left;
    margin-bottom: 8px;

    h3, .DiscountBadge {
      float: none;
      display: table;
    }

    .DiscountBadge {
      margin: 0;
    }
  }
}
