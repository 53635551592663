@import '../../scss/colors';

.Footer {
  padding: 36px 10px;
  background: $dark-gray;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  .InfoText {
    color: $gray;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $gray-2;
      text-decoration: none;
    }
  }
}

@media (max-width: 767.98px) {
  .Footer .container .row > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
