@import '../../scss/const';
@import '../../scss/colors';

.FormLoader {
  margin: auto;
}

.FormExplanation {
  text-align: right;
  font-size: 13px;
  color: $gray;
}

.FormItem {
  .FormLabel {
    font-weight: bold;
    padding: 0 5px;
  }

  .FormFieldRequiredAsterisk {
    padding-left: 3px;
    color: $gray;
  }

  margin: 10px 0;

  &.Hidden, .Hidden {
    display: none;
  }
}

.PaymentOption .Select {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .PaymentOption .row {
    margin: 0;

    > div {
      padding: 0;
    }

    .PaymentOptionLogo svg {
      width: 90%;
    }
  }
}

.PreferenceDropdown, .AttendeesDropdown {
  width: 100%;
}

.AttendeesDropdown .Select {
  margin-bottom: 0;
}

#PersonalizeTicketForm .Disabled {
  opacity: 0.2;
}

.EditDetailsButton {
  padding: 0.375rem 0;
  font-weight: bold;
  text-decoration: none;
  color: $gray;
  float: right;

  &:hover {
    color: $dark-gray;
    text-decoration: none;
  }
}

.ButtonSpinner {
  display: inline-block;
  height: 16px;
  padding-left: 7px;

  .spinner-border {
    border-width: 3px;
    display: block;
  }

  &.ButtonSpinner_Icon {
    padding-left: 0;
    width: 22.5px;
    display: flex;
    justify-content: center;
  }
}

.CreditCardForm {
  .mollie-component {
    padding: 8px 10px;
    overflow: auto;
    background: $gray-3;
  }

  .label {
    font-size: 14px;
    margin-bottom: 4px;
  }
}
