@import '../../scss/const';
@import '../../scss/colors';

.Login .Main {
  padding: 60px 0;
}

.LoginBlock {
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  padding: 20px;
}
